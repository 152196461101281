import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { node } from 'prop-types';
import bg from '../../../public/static/bg-8f61f4d4f8e590d35ce2445f7f942643.png';

import Favicon from '@assets/images/dinda-indra.png';
import '@assets/css/icomoon.css';
import '@assets/css/bootstrap.css';
import '@assets/css/style.css';

const IMAGE_URL = `https://wedding.mubashira.mk/static/thumbnail-123321.png`;
const META_DESCRIPTION = `We extend a heartfelt invitation to you and your family to share in the commencement of our new life
journey, and your presence will bring us immense joy and blessings.`;

function MainLayout({ children }) {
  return (
    <Fragment>
      <Helmet>
        <title>Azhar ❤️ Mubashira Wedding </title>

        {/* Favicon */}
        <link rel="icon" type="image/png" href={Favicon} />

        {/* font and SEO tags */}
        <meta property="og:title" content="The Wedding of Mubashira & Muhammad Azhar" />
        <meta property="og:image" content={IMAGE_URL} />
        <meta property="og:url" content="https://wedding.mubashira.mk" />
        <meta property="og:site_name" content="The Wedding of Mubashira & Muhammad Azhar" />
        <meta property="og:description" content={META_DESCRIPTION} />
        <meta name="twitter:title" content="The Wedding of Mubashira & Muhammad Azhar" />
        <meta name="twitter:description" content={META_DESCRIPTION} />
        <meta name="twitter:image" content={IMAGE_URL} />
        <meta name="twitter:url" content="https://mubashira.mk" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@idindrakusuma" />

        <link
          href="https://fonts.googleapis.com/css?family=Work+Sans:400,300,600,400italic,700"
          rel="stylesheet"
          type="text/css"
        />
        <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet"></link>
      </Helmet>
      <div id="page">{children}</div>
    </Fragment>
  );
}

MainLayout.propTypes = {
  children: node.isRequired,
};

export default MainLayout;
